<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="users"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="usersPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('user_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New User
                  </v-btn>
                </template>
                <v-card :class="customClass">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- First Name -->
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedUser.first_name"
                              :rules="firstNameRules"
                              label="First Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  First Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Last Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedUser.last_name"
                              :rules="lastNameRules"
                              label="Last Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Last Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Email -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedUser.email"
                              :rules="emailRules"
                              label="Email"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Email <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- First Phone Number -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedUser.phone1"
                              :rules="phone1Rules"
                              label="First Phone Number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  First Phone Number
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Second Phone Number -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedUser.phone2"
                              :rules="phone2Rules"
                              label="Second Phone Number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">Second Phone Number</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Password -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              @click:append="showPassword = !showPassword"
                              v-model="password"
                              :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showPassword ? 'text' : 'password'"
                              :rules="passwordRules"
                              label="Password"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Password
                                  <span
                                    v-if="editedIndex === -1"
                                    class="red--text"
                                    >*</span
                                  >
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Roles -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="roleNames"
                              :items="roleNamesList"
                              attach
                              chips
                              label="Roles"
                              multiple
                            >
                              <template v-slot:label>
                                <p class="body-1">Roles</p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Ative -->
                          <v-col class="mt-n5" cols="12">
                            <v-checkbox
                              v-model="editedUser.active"
                              :label="`Active`"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeTemp">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteUserConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('user_update')"
              small
              class="mr-2"
              @click="editUser(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('user_delete')"
              small
              @click="deleteUser(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Users per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementUsers",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "First Phone Number", value: "phone1" },
        { text: "Second Phone Number", value: "phone2" },
        { text: "Active", value: "active" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      roles: [],
      roleNames: [],
      roleNamesList: [],
      editedIndex: -1,
      editedUser: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone1: "",
        phone2: "",
        password: "",
        email_verified_at: "",
        company_id: 0,
        active: true,
        roles: [],
        created_at: "",
        updated_at: "",
      },
      defaultUser: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        phone1: "",
        phone2: "",
        password: "",
        email_verified_at: "",
        company_id: 0,
        active: true,
        roles: [],
        created_at: "",
        updated_at: "",
      },

      tempUser: {},

      // first name
      firstNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // last name
      lastNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) =>
          v.length <= 255 || "Last name can not be more than 255 characters",
      ],

      // email
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // First Phone Number
      phone1Rules: [
        (v) => !!v || "Required",
        (v) => v.length <= 20 || "Can not be more than 20 characters",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      // Second Phone Number
      phone2Rules: [
        // (v) => !!v || "Required",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      // password
      password: "",
      passwordRules: [
        // (v) => !!v || "Should not be empty password",
        // (v) => v.length >= 8 || "Can not be less than 8 characters",
        // (v) => v.length <= 30 || "Can not be more than 30 characters",
      ],

      // Show Password
      showPassword: false,

      page: 1,
      usersPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      customClass: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedUsers();
    },

    // usersPerPage
    usersPerPage() {
      this.getApiPagedUsers();
    },

    // perPageChoice
    perPageChoice(val) {
      this.usersPerPage = val.value;
      this.page = 1;
    },

    // // editedUser.phone1
    // "editedUser.phone1"(val) {
    //   if (val != null && val != "") {
    //     this.editedUser.phone1 = this.$helpers.formatInputMobileNumber(val);
    //   }
    // },

    // // editedUser.phone2
    // "editedUser.phone2"(val) {
    //   if (val != null && val != "") {
    //     this.editedUser.phone2 = this.$helpers.formatInputMobileNumber(val);
    //   }
    // },
  },

  created() {
    if (!this.$can("user_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // customClass
    this.customClass = "padding-bottom";

    // this.getApiUsers();
    this.getApiPagedUsers();

    this.getApiRoles();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiUser();
        } else {
          this.createApiUser();
        }
      }
    },

    // editUser
    editUser(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editedUser = Object.assign({}, user);
      this.tempUser = user;
      this.dialog = true;

      if (this.editedIndex != -1) {
        // this.editedUser.roles = [1, 2];

        this.roleNames = this.$helpers.getNamesByIdsFromList(
          this.roles,
          this.editedUser.roles
        );
      }

      if (this.editedIndex !== -1) {
        this.$refs.form.validate();
      }
    },

    // deleteUser
    deleteUser(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editedUser = Object.assign({}, user);
      this.dialogDelete = true;
    },

    // deleteUserConfirm
    deleteUserConfirm() {
      this.deleteApiUser();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
        this.roleNames = [];
      });

      this.$refs.form.reset();
    },

    // closeTemp
    closeTemp() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
        this.roleNames = [];
      });

      // console.log("this.tempUser", this.tempUser);
      Object.assign(this.users[this.editedIndex], this.tempUser);
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Users
    async getApiUsers() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("users");

      if (res.status == 200) {
        this.users = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Users
    async getApiPagedUsers() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `users/paged?page_no=${this.page}&page_size=${this.usersPerPage}`
      );

      if (res.status == 200) {
        this.users = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api User
    async createApiUser() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "users",
        {
          first_name: this.editedUser.first_name,
          last_name: this.editedUser.last_name,
          password: this.password,
          email: this.editedUser.email,
          phone1: this.editedUser.phone1,
          phone2: this.editedUser.phone2,
          roles: this.$helpers.getIdsByNamesFromList(
            this.roles,
            this.roleNames
          ),
          active: this.editedUser.active,
        },
        "User Created Successfully!"
      );

      if (res.status == 200) {
        this.users.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api User
    async updateApiUser() {
      this.overlay = true;

      var reqData = {
        id: this.editedUser.id,
        first_name: this.editedUser.first_name,
        last_name: this.editedUser.last_name,
        email: this.editedUser.email,
        phone1: this.editedUser.phone1,
        phone2: this.editedUser.phone2,
        roles: this.$helpers.getIdsByNamesFromList(this.roles, this.roleNames),
        active: this.editedUser.active,
      };

      if (this.password != "" || this.password != null) {
        reqData.password = this.password;
      }

      const res = await this.$helpers.updateApiData(
        `users/${this.editedUser.id}`,
        reqData,
        "User Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.users[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api User
    async deleteApiUser() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `users/${this.editedUser.id}`,
        "User Deleted Successfully!"
      );

      if (res.status == 200) {
        this.users.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Roles
    async getApiRoles() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("roles");

      if (res.status == 200) {
        this.roles = res.data;

        this.roleNamesList = this.$helpers.getNamesFromList(this.roles);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-bottom {
  padding-bottom: 110px;
}
</style>
