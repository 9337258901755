<template>
  <div class="background">
    <Users />
  </div>
</template>

<script>
import Users from "@/components/elements/dashboard/users/Users";

export default {
  name: "DocumentManagementUsers",

  components: {
    Users,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
